<template>
  <rpm-layout>
    <div class="flex justify-between items-center bg-white rounded-lg p-2 mb-2 space-x-1">
      <div class="relative w-1/4">
        <RpmSearch v-model="search"></RpmSearch>
      </div>
      <div>
        <span class="text-sm mr-2">Category:</span>
        <el-select v-model="selectedCategory" placeholder="Select Category">
          <el-option
            v-for="category in ['all', 'rpm', 'ccm']"
            :key="category"
            :label="category"
            :value="category"
          ></el-option>
        </el-select>
      </div>
      <div>
        <span class="text-sm mr-2">Status:</span>
        <el-select v-model="selectedStatus" placeholder="Select Status">
          <el-option
            v-for="status in ['all', 'open', 'closed']"
            :key="status"
            :label="status"
            :value="status"
          ></el-option>
        </el-select>
      </div>
      <div>
<!--        <v-tooltip bottom>-->
<!--          <template v-slot:activator="{ on, attrs }">-->
<!--            <v-icon class="-mt-2 -ml-2" v-bind="attrs" v-on="on" size="medium">mdi-help-circle</v-icon>-->
<!--          </template>-->
<!--          <span>-->
<!--            Hide tasks with empty due dates.-->
<!--          </span>-->
<!--        </v-tooltip>-->
        <el-checkbox class="text-sm" v-model="hideEmptyDueDates" label="Hide Empty Due Date"></el-checkbox>
      </div>
      <div>
<!--        <v-tooltip bottom>-->
<!--          <template v-slot:activator="{ on, attrs }">-->
<!--            <v-icon class="-mt-2 -ml-2" v-bind="attrs" v-on="on" size="medium">mdi-help-circle</v-icon>-->
<!--          </template>-->
<!--          <span>-->
<!--            Show only tasks that are overdue.-->
<!--          </span>-->
<!--        </v-tooltip>-->
        <el-checkbox v-model="showOnlyDue" label="Show Only Due Tasks"></el-checkbox>
      </div>
      <div>
        <el-button type="default" @click="refresh">Refresh</el-button>
      </div>
    </div>
    <div class="relative overflow-x-auto sm:rounded-lg">
      <v-data-table :loading="loading" :headers="dtHeaders" :items="dtData" @click:row="goToPatient($event.patient.patient_id)" :search="search" sort-by="due_date" class="v-data-table cursor-pointer">
        <template v-slot:[`item.patient`]="{ item }">
          <button @click.stop="goToPatient(item.patient.patient_id, true)"><v-icon small>mdi-open-in-new</v-icon></button>
          {{ item.patient.patient_name }}
        </template>
        <template v-slot:[`item.created_at`]="{ item }">
          {{ item.created_at | datetime}}
        </template>
        <template v-slot:[`item.due_date`]="{ item }">
          <span :class="{ 'text-red-700': isOverdue(item.due_date) }">{{ item.due_date | dateNonLocal }}</span>
        </template>
      </v-data-table>
    </div>
  </rpm-layout>
</template>

<script>
import moment from "moment-timezone";
import RpmLayout from "../../layouts/RpmLayout.vue";
import RpmSearch from "@/components/RpmSearch.vue";
import PatientMixin from "@/mixins/Patient";
import Template from "@/mixins/Template";
import Vue from "vue";

export default {
  mixins: [PatientMixin],
  components: {Template, RpmSearch, RpmLayout},
  data() {
    return {
      accessToken: null,
      loading: true,
      search: "",
      hideEmptyDueDates: true,
      showOnlyDue: false,
      selectedStatus: 'open',
      selectedCategory: 'all',
    };
  },
  created: async function () {
    this.accessToken = await this.$auth.getTokenSilently();
    Promise.all([
      !this.$store.state.patients.authUser.id ? this.$store.dispatch('getAuthUser') : null,
      !this.$store.state.patients.tasks.length ? this.$store.dispatch('getTasks') : null,
    ]).finally(() => {
      this.loading = false;
    });
  },
  mounted: function () {
  },
  computed: {
    authUser() {
      return this.$store.state.patients.authUser
    },
    dtHeaders() {
      return [
        {text: 'ID', value: 'id'},
        {text: 'Due Date', value: 'due_date'},
        {text: 'Created By', value: 'creator_name'},
        {text: 'Assigned To', value: 'assigned_name'},
        {text: 'Patient', value: 'patient'},
        {text: 'Name', value: 'name'},
        {text: 'Description', value: 'description'},
        {text: 'Category', value: 'category'},
        {text: 'Status', value: 'status'},
        {text: 'Duration', value: 'duration'},
        {text: 'Created At', value: 'created_at'},
      ];
    },
    dtData() {
      return this.filteredTasks;
    },
    filteredTasks() {
      return this.$store.state.patients.tasks.filter(task => {
        return !(this.hideEmptyDueDates && !task.due_date);
      }).filter(task => {
        if (this.selectedStatus === 'all') {
          return true;
        }
        return task.status === this.selectedStatus;
      }).filter(task => {
        if (this.showOnlyDue) {
          return this.isOverdue(task.due_date);
        }
        return true;
      }).filter(task => {
        if (this.selectedCategory === 'all') {
          return true;
        }
        return task.category === this.selectedCategory;
      });
    },
  },
  methods: {
    isOverdue(dueDate) {
      return dueDate && moment(dueDate).isBefore(moment(), 'day');
    },
    refresh() {
      this.loading = true;
      this.$store.dispatch('getTasks').finally(() => this.loading = false);
    },
  },
};
</script>
